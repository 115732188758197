'use strict';

const extText = leavingSiteParams.extText;
const mpText = leavingSiteParams.mpText;
const lmpText = leavingSiteParams.lmpText;
const irText = leavingSiteParams.irText;

document.addEventListener('DOMContentLoaded', () => {
	let { links } = document;
	links = Array.prototype.slice.call(links);

	links.forEach((link) => {
		// /medicines or /pipeline
		if (link.pathname.includes('/medicines') || link.pathname.includes('/pipeline')) {
			link.target = '_blank';
			link.setAttribute('rel', 'noopener');
			link.setAttribute('data-external-link', true);
			link.setAttribute('data-mp-link', true);
			link.setAttribute('data-micromodal-trigger', 'leaving-site-modal');
		} 
		// medical professionals
		else if (link.pathname.includes('/professionisti-medici')) {
			link.target = '_blank';
			link.setAttribute('rel', 'noopener');
			link.setAttribute('data-external-link', true);
			link.setAttribute('data-lmp-link', true);
			link.setAttribute('data-micromodal-trigger', 'leaving-site-modal');
		} 
		// ir
		else if (link.hostname === 'ir.blueprintmedicines.com') {
			link.target = '_blank';
			link.setAttribute('rel', 'noopener');
			link.setAttribute('data-external-link', true);
			link.setAttribute('data-ir-link', true);
			link.setAttribute('data-micromodal-trigger', 'leaving-site-modal');
		} 
		// external sites
		else if (link.hostname !== window.location.hostname) {
			link.target = '_blank';
			link.setAttribute('rel', 'noopener');
			link.setAttribute('data-external-link', true);
			link.setAttribute('data-ext-link', true);
			link.setAttribute('data-micromodal-trigger', 'leaving-site-modal');
		} 
		// else if it a pdf
		else if (link.href.substr(link.href.length - 4) === '.pdf') {
			link.target = '_blank';
		} 
		// otherwise
		else {
		}
	});

	const externalLinks = document.querySelectorAll('[data-external-link]');

	externalLinks.forEach((externalLink) => {
		const externalLinkNotification = document.createElement('span');
		externalLinkNotification.classList.add('clip');
		externalLinkNotification.innerHTML = ' (opens in new tab)';
		externalLink.appendChild(externalLinkNotification);
		externalLink.addEventListener('click', (e) => {
			e.preventDefault();
			const linkDestination = externalLink.getAttribute('href');
			const continueToSite = document.getElementById('continue-to-site');
			continueToSite.target = '_blank';
			continueToSite.setAttribute('href', linkDestination);
			const leavingSiteModalText = document.querySelector('#leaving-site-modal-content p');
			const modalContainer = document.querySelector('#leaving-site-modal .modal__container');

			// medicines/pipeline
			if (externalLink.getAttribute('data-mp-link')) {
				leavingSiteModalText.innerText = mpText;
				modalContainer.setAttribute('data-mp-site', true);
			} 
			// medical professionals
			else if (externalLink.getAttribute('data-lmp-link')) {
				leavingSiteModalText.innerText = lmpText;
				modalContainer.setAttribute('data-lmp-site', true);
			} 
			// ir
			else if (externalLink.getAttribute('data-ir-link')) {
				leavingSiteModalText.innerText = irText;
				modalContainer.setAttribute('data-ir-site', true);
			} 
			// external
			else {
				leavingSiteModalText.innerText = extText;
				modalContainer.setAttribute('data-ext-site', true);
			}
			MicroModal.show('leaving-site-modal');
		});
	});

	const closeModal = document.getElementById('close-modal');

	closeModal.addEventListener('click', () => {
		MicroModal.close('leaving-site-modal');
	});

	const continueToSite = document.getElementById('continue-to-site');

	continueToSite.addEventListener('click', () => {
		MicroModal.close('leaving-site-modal');
	});
});
