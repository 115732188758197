const languageToggles = document.querySelectorAll( '[data-language-toggle]' );

if ( languageToggles ) {
	languageToggles.forEach( ( languageToggle, index ) => {
		languageToggle.disabled = false;
		languageToggle.setAttribute( 'aria-expanded', 'false' );
		languageToggle.addEventListener( 'click', () => {
			// console.log( languageToggle );
			const languageOptions = languageToggle.closest( '.language-toggle' ).querySelector( '[data-language-options]' );
			// console.log( languageOptions );
			if ( languageToggle.getAttribute( 'aria-expanded' ) === 'false' ) {
				languageToggle.setAttribute( 'aria-expanded', 'true' );
				languageOptions.setAttribute( 'aria-hidden', 'false' );
				languageOptions.hidden = false;
			} else {
				languageToggle.setAttribute( 'aria-expanded', 'false' );
				languageOptions.setAttribute( 'aria-hidden', 'true' );
				languageOptions.hidden = true;
			}
			// console.log( languageToggle.getAttribute( 'aria-expanded' ) );
		});
	});
}

document.addEventListener( 'click', (e) => {
	// console.log( e.target );
	// console.log( e.target.hasAttribute( 'data-language-toggle' ) );
	if ( ! e.target.hasAttribute( 'data-language-toggle' ) ) {
		languageToggles.forEach( ( languageToggle, index ) => {
			if ( languageToggle.getAttribute( 'aria-expanded' ) === 'true' ) {
				languageToggle.setAttribute( 'aria-expanded', 'false' );
				const languageOptions = document.querySelectorAll( '[data-language-options]' );
				languageOptions.forEach( languageOption => {
					languageOption.setAttribute( 'aria-hidden', 'true' );
					languageOption.hidden = true;
				});
			}
		});
	}
});
