TweenMax.to('.green-piece', 70, {
	rotation: 360,
	translate: 2,
	ease: Linear.easeNone,
	repeat: -1
});

TweenMax.to('.grey-piece', 64, {
	rotation: -360,
	ease: Linear.easeNone,
	repeat: -1
});

TweenMax.to('.blue-piece', 54, {
	rotation: -360,
	ease: Linear.easeNone,
	repeat: -1
});
