document.addEventListener( "DOMContentLoaded", function() {
jQuery(".btn-news-alert").click(function () {
    jQuery('.btn-news-alert').toggleClass('open-alert');
    jQuery('.news-alert-container').toggleClass('open-alert');
  });

  setTimeout(function(){

    jQuery(".blueprint-home-slider .btn-news-alert").click();

    // setTimeout(function(){

      // jQuery(".blueprint-home-slider .btn-news-alert").click();

    // },10000);

  },1000);
})